import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import { TimelineOppositeContent ,TimelineSeparator , TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';
import { Stack , Card, CardHeader } from '@mui/material';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import SchoolIcon from '@mui/icons-material/School';

export default function CustomizedTimelineItemNarrow({color , num ,title ,date , subtitle , location , body ,img}) {
    const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

    function getCurrentDimension(){
      return {
            width: window.innerWidth,
            height: window.innerHeight
      }
    }

    React.useEffect(() => {
          const updateDimension = () => {
                setScreenSize(getCurrentDimension())
          }
          window.addEventListener('resize', updateDimension);
  
      
          return(() => {
              window.removeEventListener('resize', updateDimension);
          })
    }, [screenSize])
    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineConnector />
                    <TimelineDot sx={{backgroundColor:color}}/>              
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <Card sx={{
                    backdropFilter: "blur(10px)",
                    backgroundColor: "rgba(255,255,255,0.1)",
                    borderRadius:'10px',
                    padding:'20px'
                }}>
                    <CardHeader
                        avatar={
                            <img 
                                src={img} 
                                style={{
                                    height:'50px',
                                    width:'50px',
                                    objectFit:'contain',
                                    backgroundColor:color,
                                    borderRadius:50,
                                    padding:5
                                }}/>
                        }
                        title={
                            <Stack direction="column">
                                <h2 style={{margin:0}}>{subtitle}</h2>
                                <Stack direction={'row'} alignItems={'center'} sx={{paddingTop:'8px'}}>
                                    <CalendarTodayIcon sx={{color:'lightgray'}}/>
                                    <p style={{margin:0,marginLeft:5,marginTop:5,color:'lightgray'}}>{date}</p>
                                    <LocationOnIcon sx={{marginLeft:'10px', color:'lightgray'}}/>
                                    <p style={{margin:0,marginLeft:5,marginTop:5,color:'lightgray'}}>{location}</p>
                                </Stack>
                            </Stack>
                    }
                    />      
                    <h2 style={styles.nomargin}>{title}</h2>
                    <p style={styles.body}>{body}</p>
                </Card>
                    
            </TimelineContent>
        </TimelineItem>
    )
}

const styles = {
    nomargin:{
        margin : 0
    },
    midmargin : {
        marginTop:'5px',
        marginBottom:'5px'
    },
    body:{
        margin:0,
        textAlign:'justify'
    }
}