import { Grid, Link, Stack, Typography, Box, Card } from "@mui/material";
import airplane from "../assets/images/hobbies/travel-dynamic-gradient.png";
import eth from "../assets/images/hobbies/eth-dynamic-gradient.png";
import paint from "../assets/images/hobbies/paint-kit-dynamic-gradient.png";
import wifi from "../assets/images/hobbies/wifi-dynamic-gradient.png";
import gym from "../assets/images/hobbies/gym-dynamic-gradient.png";
import { Zoom } from "react-awesome-reveal";

export default function Hobbies() {
  return (
    <Box sx={{padding:"20px",marginBottom:'20px'}}>
      <Box sx={{ marginBottom: "20px" }}>
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          Hobbies
        </Typography>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Here is how I spend my week ends !
        </Typography>
      </Box>
      <Grid container alignItems="stretch" spacing="20px" justifyContent="center">
        <Grid item md={3} sx={{display:'flex'}}>
        <Card
          sx={{
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(7px)",
            backgroundColor: "rgba(255,255,255,0.1)",
            borderRadius:'20px',
            padding:'20px',
          }}
        >
          <Zoom cascade triggerOnce style>
            <img src={paint} style={{ height: "300px" , width : '100%' , objectFit: 'contain'}} />
            <Typography style={{ textAlign: "center", fontSize: 45 }}>
              Painting
            </Typography>
            <Typography style={{ fontSize: 20 , textAlign : "justify" }}>
              I learned to paint when I was a child but completely stopped at my
              teenage years. I just recently started to get back to It. It is
              very relaxing and let me work on my patience. My current project
              is a travel log where I draw everything I see abroad and keep logs of
              what I did.
            </Typography>
          </Zoom>
        </Card>
        </Grid>
        <Grid item md={3} sx={{display:'flex'}}>
        <Card
          sx={{
            flex : 1,
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(7px)",
            backgroundColor: "rgba(255,255,255,0.1)",
            borderRadius:'20px',
            padding:'20px'
          }}
        >
          <Zoom cascade triggerOnce>
            <img src={wifi} style={{ height: "300px" , width : '100%' , objectFit: 'contain'}} />
            <Typography style={{ textAlign: "center", fontSize: 45 }}>
              Self Hosting
            </Typography>
            <Typography style={{ textAlign: "justify", fontSize: 20 }}>
              One day I stumbled upon upon awesome-selfhosted and my home
              network was never the same. I use a raspberrypi 4 to self
              host different services : from a digital book and photo library to
              my own DNS or reverse proxy. This has been super instructive about
              networks and containerization. I use what I learned whenever I work
              with cloud products.
            </Typography>
          </Zoom>
        </Card>
        </Grid>
        <Grid item md={3} sx={{display:'flex'}}>
        <Card
          sx={{
            flex:1,
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(7px)",
            backgroundColor: "rgba(255,255,255,0.1)",
            borderRadius:'20px',
            padding:'20px'   }}
        >
          <Zoom cascade triggerOnce>
            <img src={gym} style={{ height: "300px" , width : '100%' , objectFit: 'contain'}} />
            <Typography style={{ textAlign: "center", fontSize: 45 }}>
              Sports
            </Typography>
            <Typography style={{ textAlign: "justify", fontSize: 20 }}>
              I started sports at 5 years old with Judo and Handball. In my
              Teenage years, I stopped both to play basketball. Once I went to
              college I hit the gym and still do to that day !
            </Typography>
          </Zoom>
        </Card>
        </Grid>
        <Grid item md={3} sx={{display:'flex'}}>
        <Card
          sx={{
            flex:1,
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(7px)",
            backgroundColor: "rgba(255,255,255,0.1)",
            borderRadius:'20px',
            padding:'20px'   }}
        >
          <Zoom cascade triggerOnce>
            <img src={eth} style={{ height: "300px" , width : '100%' , objectFit: 'contain'}} />
            <Typography style={{ textAlign: "center", fontSize: 45 }}>
              Blockchain
            </Typography>
            <Typography style={{ textAlign: "justify", fontSize: 20 }}>
              I stumbled upon Blockchain technology during college and did not understood
              it at all and dismmissed it. I started to look into it as an investor a few
              years after and followed closely the different innovations of this ever changing
              field. I have started to learn about cryptography , smart contracts and cross
              chain communication protocols and will soon start implementing on chain stuff !
            </Typography>
          </Zoom>
        </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
