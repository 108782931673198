import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import MyAppBar from "./components/organisms/AppBar";
import HomePage from "./pages/HomePage";
import SkillsPage from "./pages/SkillsPage";
import AssociativeWorkPage from "./pages/AssociativeWorkPage";
import Blog from "./pages/Blog";
import Experience from "./pages/Experience";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { themeOptions } from "./utils/styles";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import bg from "./assets/images/bg.svg";
import Footer from "./components/organisms/Footer";
import Projects from "./pages/Projects";
import MaskRCNN from "./pages/Projects/MaskRCNN";
import VirtualSensing from "./pages/Projects/VirtualSensing";
import SafetyAnalysis from "./pages/Projects/SafetyAnalysis";
import GymCam from "./pages/Projects/GymCam";
import CatTree from "./pages/Projects/CatTree";
import NFTcam from "./pages/Projects/NFTcam";
import NotFound from "./pages/404";
import OverwatchTracker from "./pages/Projects/OverwatchTracker";
import Ace from "./pages/Projects/Ace";
import SlabFlow from "./pages/Projects/SlabFlow";
import BigData from "./pages/Projects/BigData";
import Hobbies from "./pages/Hobbies";
import ViteMaSpe from "./pages/Projects/ViteMaSpe";
import Home from "./Home";

const theme = createTheme(themeOptions);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <MyAppBar />
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="Projects" element={<Projects/>}/>
        <Route exact path="Associative" element={<AssociativeWorkPage/>}/>
        <Route exact path="NFTcam" element={<NFTcam />} />
        <Route exact path="MaskRCNN" element={<MaskRCNN />} />
        <Route exact path="VirtualSensing" element={<VirtualSensing />} />
        <Route exact path="SafetyAnalysis" element={<SafetyAnalysis />} />
        <Route exact path="GymCam" element={<GymCam />} />
        <Route exact path="CatTree" element={<CatTree />} />
        <Route exact path="Overwatch-Tracker" element={<OverwatchTracker />} />
        <Route exact path="Ace" element={<Ace />} />
        <Route exact path="SlabFlow" element={<SlabFlow />} />
        <Route exact path="BigData" element={<BigData />} />
        <Route exact path="ViteMaSpe" element={<ViteMaSpe />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
