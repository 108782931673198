import { Box, Chip, Divider, LinearProgress, Typography } from "@mui/material";
import { Stack } from "@mui/material";
import Blob from "../../assets/images/blob.svg";

export default function SkillCard(props) {
  return (
    <Box
      sx={{
        ...props.sx,
        flexGrow: 0,
        padding: { xs: 0, md: "20px" },
        justifyItems: "center",
        margin: "10px",
      }}
    >
      <Stack alignItems={"center"} direction="row">
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            columnGap: "20px",
          }}
        >
          {props.icon ? props.icon : <img src={Blob} className="skill-logo" />}
          <Typography variant="h5" sx={{ textAlign: "center" }}>
            {props.name ? props.name : "Skill Name"}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 3,
          }}
        >
          <LinearProgress
            sx={{
              "& .MuiLinearProgress-bar": {
                background: "white",
                borderRadius: 5,
              },
              background: "#607274",
              height: "8px",
              borderRadius:5
            }}
            variant="determinate"
            value={props.rating * 20}
          />
        </Box>
      </Stack>
    </Box>
  );
}
