import { Card, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { SwiperSlide } from "swiper/react";

export default function SkillAccordion({ title, icon, children }) {
  return (
    <Card
      style={{
        display: "flex",
        flexDirection:"column",
        minHeight:"600px",
        height:'100%',
        minWidth:'45%',
        flex:1,
        flexGrow: 1,
        padding: {xs:"1px",md:20},
        borderRadius: 20,
        backgroundColor: "rgba(255,255,255,0.1)",
        backdropFilter: 'blur(10px)'
      }}
      //className="card-hoverable"
    >
      <Stack alignItems="center">
        {icon}
        <Typography
          variant="h4"
          sx={{
            margin: 0,
            marginTop: { xs: "20px", md: "20px" },
            textAlign: "center",
          }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        spacing={0}
        justifyContent="center"
        flexWrap="wrap"
        sx={{marginBottom : {xs:5,md:0}}}
      >
        {children}
      </Stack>
    </Card>
  );
}
