import React from "react";
import { Grid , Typography , Box } from "@mui/material";

export default function Ace(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    ACE
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Crane Automation for Coil Handling
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    I arrived at ArcelorMittal in July 2021 as a Junior Developper. I had the task to maintain an Optimization algorithm for Crane Automation . The mission
                    included discussion with the supply chain workers and blue collars on how to improve the system.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How it works
                </Typography>
                <div style={{width:"600px",marginLeft:"auto",marginRight:"auto",align:"center"}}>
                    <img src='https://s.wsj.net/public/resources/images/BN-IH321_arcelo_J_20150507023809.jpg' style={{width:"600px"}}/>
                </div>
                
                <Typography variant="h6" style={textStyle}>
                    Two cranes travel in the warehouse to take coils from a slot to another. We call this a job. The model has two core components : a job generator and a 
                    job scheduler. The Job Generator uses an image of the stock and what needs to be done to generate missions and optimizes which slot to place the coil
                    in. The Job Schduler uses th list of Jobs to do and determine which job each crane will execute and in what order. It optimizes the frequency of job
                    fullfilment and optimizes crane trajectories. These two parts relies on costs , mathematical translation of the clients needs and goals. 
                    Both Algorithms tries to limit their output total cost , and by doing so , optimize the perfomances of the cranes. 
                </Typography>

                <Typography variant="h4" style={textStyle}>
                   What we Did
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    We gathered the need of the supply chain for this warehouse and gathered impressions from workers on the field to understand what would be improved.
                    Then for a few months had a back and fourth with the supply chain as we were rolling out improved versions of the algorithm. We implemented new features
                    to help the specific needs of both supply chain and automatism. The model kept improving and I kept contact with supply chain for further support while
                    continuing on other projects for ArcelorMittal
                </Typography>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}