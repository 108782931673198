import { Box, Card, Divider, Grid, Typography } from "@mui/material";

import dassault from "../assets/images/experience/dassault.svg";
import terega from "../assets/images/experience/terega.png";
import arcelor from "../assets/images/experience/arcelor_mittal.png";
import SAFRAN from "../assets/images/experience/safran.png";
import adeo from "../assets/images/experience/adeo.png";

import { useState, useLayoutEffect, StyleSheet } from "react";
import { Stack } from "@mui/system";
import Mooc from "../components/atom/Mooc";

import docker from "../assets/images/Skills/docker.svg";
import kubernetes from "../assets/images/Skills/kubernetes.svg";
import gcp from "../assets/images/Skills/googlecloud.svg";
import terraform from "../assets/images/Skills/terraform.svg";

import CustomizedTimeline from "../components/molecules/Timeline";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

export default function Experience() {
  return (
    <Grid container sx={styles.container}>
      <Typography
        variant="h1"
        sx={{ textAlign: "center", fontSize: { xs: 60, md: 100 } }}
      >
        Experience
      </Typography>
      <Typography
        variant="h5"
        sx={{ textAlign: "center", marginBottom: "20px" }}
      >
        Here is a timeline of what has been keeping me busy
      </Typography>
      <CustomizedTimeline />
      <Stack marginTop={10} marginBottom={5}>
        <Typography variant="h3" align="center">
          Companies I have Worked with
        </Typography>

        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          paddingLeft={2}
          paddingRight={2}
          paddingTop={2}
          style={styles.stack}
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={styles.company_card} className="card-hoverable">
              <a href="https://www.safran-group.com/" target="_blank">
                <img src={SAFRAN} style={styles.company_img} />
              </a>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Card sx={styles.company_card} className="card-hoverable">
              <a href="https://www.dassault-aviation.com/en/" target="_blank">
                <img src={dassault} style={styles.company_img} />
              </a>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Card sx={styles.company_card} className="card-hoverable">
              <a href="https://www.terega.fr/en" target="_blank">
                <img src={terega} style={styles.company_img} />
              </a>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          direction={{ xs: "column", md: "row" }}
          paddingLeft={2}
          paddingRight={2}
          paddingTop={0}
          style={styles.stack}
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={3}>
            <Card sx={styles.company_card} className="card-hoverable">
              <a href="https://corporate.arcelormittal.com/" target="_blank">
                <img href="#" src={arcelor} style={styles.company_img} />
              </a>
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Card sx={styles.company_card} className="card-hoverable">
              <a href="https://www.adeo.com/" target="_blank">
                <img href="#" src={adeo} style={styles.company_img} />
              </a>
            </Card>
          </Grid>
        </Grid>
      </Stack>

      <Stack marginTop={10} marginBottom={5} alignItems={"center"}>
        <Typography variant="h3" align="center" sx={{ marginBottom: 2 }}>
          Moocs I have Completed
        </Typography>
        <Stack
          alignItems={"center"}
          direction="row"
          sx={{
            marginLeft: "20px",
            marginRight: "20px",
            maxWidth: "1600px",
            flexWrap: "wrap",
            gap: "20px",
          }}
        >
          <Mooc
            img={gcp}
            platform="Cloud Guru"
            date="2023"
            course="Google Certified Associate Cloud Engineer 2020"
          />
          <Mooc
            img={terraform}
            platform="freeCodeCamp"
            date="2023"
            course="Terraform Course - Automate your AWS cloud infrastructure"
          />
          <Mooc
            img={kubernetes}
            platform="TechWorld"
            date="2023"
            course="Kubernetes Zero to Hero"
          />
          <Mooc
            img={docker}
            platform="TechWorld"
            date="2021"
            course="Docker Zero to Hero"
          />
        </Stack>
      </Stack>
      <Stack sx={{display:"flex",flexDirection:"column"}} marginTop={10} marginBottom={5} alignItems="center">
        <Typography variant="h3" align="center">
          Certifications
        </Typography>
        <Stack sx={{display:"flex", flex: 1,margin: "20px",minWidth:"80%"}}>
          <a target="_blank" href="https://nodeguardians.io/character/qdeli/journal">
          <Mooc
            img="https://nodeguardians.io/assets/ng_n_g_logo.svg"
            platform="Node Guardians"
            date="2024"
            course="Various Quests About Solidity and Cryptography"
          />
          </a>
        </Stack>
      </Stack>
      <Divider
        sx={{ marginLeft: "10%", marginRight: "10%", backgroundColor: "gray" }}
      />
    </Grid>
  );
}

const styles = {
  container: {
    padding: "10px",
    display: "inline",
  },
  company_card: {
    flex: 1,
    padding: 4,
    height: 110,
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(10px)",
    backgroundColor: "rgba(255,255,255,0.1)",
  },
  company_img: {
    height: 100,
    objectFit: "contain",
    width: "100%",
  },
  chrono_theme: {
    secondary: "#2D4263",
    primary: "#C84B31",
    cardBgColor: "#30303000",
    cardForeColor: "white",
    titleColor: "white",
  },
  chrono_icons: {
    objectFit: "contain",
    height: "50px",
    width: "50px",
    backgroundColor: "black",
    borderRadius: 10,
  },
  stack: {
    justifyContent: "center",
    marginTop: 1,
  },
};
