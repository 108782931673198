export const themeOptions = {
    /*breakpoints:{
      values:{
        xs:0,
        md:1270
      }
    },*/
    palette: {
      type: 'dark',
      text:{
        primary:"#fff",
        secondary:'rgba(255, 255, 255, 0.7)',
        disabled:'rgba(255, 255, 255, 0.5)',
        hint:'rgba(255, 255, 255, 0.5)'
      },
      primary: {
        main: '#2D4263',
        light: '#B6CDF2',
        dark: '#557DBD',
      },
      secondary: {
        main: '#C84B31',
        light: '#F29583',
        dark: '#BD452D',
      },
      background: {
        paper: '#303030',
        default: '#191919',
      },
    },
    components:{
        MuiLink:{
            styleOverrides:{
                root:{
                    color:'white'
                }
            }
        },
        MuiButton:{
            styleOverrides:{
                root:{
                    color:'white'
                }
            }
        },
        MuiTypography:{
          styleOverrides:{
              root:{
                  color:'white'
              }
          }
        },
        MuiChip:{
          styleOverrides:{
              root:{
                  backgroundColor:'#2D4263'
              }
          }
        },
    }
  };