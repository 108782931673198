import HomePage from "./pages/HomePage";
import SkillsPage from "./pages/SkillsPage";
import Experience from "./pages/Experience";
import Projects from "./pages/Projects";
import AssociativeWorkPage from "./pages/AssociativeWorkPage";
import Blog from "./pages/Blog";
import Hobbies from "./pages/Hobbies";

export default function Home() {
  return (
    <div style={{overflow:'clip'}}>  
      <HomePage />
      <SkillsPage />
      <Experience />
      <Hobbies />
    </div>
  );
}
