import { Card, Box, Stack, Typography } from "@mui/material";

export default function Mooc({ img, date, platform, course, link }) {
  return (
    <Card
      style={{
        display: "flex",
        height:'100%',
        minWidth:'45%',
        flex:1,
        flexGrow: 1,
        padding: 20,
        borderRadius: 20,
        backgroundColor: "rgba(255,255,255,0.1)",
        backdropFilter: 'blur(10px)'
      }}
      className="card-hoverable"
    >
      <Stack direction="row" alignItems="center">
        <img src={img} style={{ height: 80, width: 80 }} />
        <Stack style={{ marginLeft: 20 }}>
          <Stack direction="row">
            <Typography variant="h5" color="#c84b31">
              {platform}
            </Typography>
            <Typography variant="h5" color="#3e5b87" style={{ marginLeft: 10 }}>
              {date}
            </Typography>
          </Stack>
            <Typography variant="h5">{course}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
