import React from "react";
import { Grid , Typography , Box } from "@mui/material";
import YouTube from 'react-youtube';

export default function NFTcam(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    NFTcam
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Own & Sell your pictures !
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h6" style={textStyle}>
                    We all take picture everyday , we store them but do we own them properly ?
                    Well ... not really. NFTcam enables you to truly own your pictures through
                    blockhain with NFT !
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    NFT ? What is that ?
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    Non Fungible Tokens (or NFT) are virtual assets which you can see as 
                    propriety title. These tokens are secured and crypted on the blockchain.
                    “Non-fungible” more or less means that it’s unique and can’t be replaced
                    with something else. So a File (or any virtual asset) can only have one NFT.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    Use your phone to certify your fresh NFTs
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    Every NFT on the shop or in your collection will be generated at the moment
                    you take a picture with the App. No more worrrying if the NFT you would like to
                    buy is linked to the original file. Buy and sell NFT for pictures you love worry free !
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    What's the catch ?
                </Typography>
                <Typography variant="h6" style={textStyle}>
                At NFTcam we want the community to exchange NFT not for money , but 
                for the sake of having nice things , putting back art and emotions 
                into the concepts of NFTs. We think NFTs should not be purely financial assets.
                </Typography>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}