import React from "react";
import { Grid , Typography , Box, Stack, Button } from "@mui/material";
import ovwtracker from '../../assets/images/ovwtracker.png'
import { SvgIcon } from "@mui/material";
import LinuxLogo from '../../assets/images/linux.js'
import WindowsLogo from "../../assets/images/windows";
import AppleLogo from "../../assets/images/apple";
import { Paper } from "@mui/material";
import GitHubIcon from '@mui/icons-material/GitHub';

export default function OverwatchTracker(){

    const PaperButton = {fontSize:20,backgroundColor:'transparent',borderColor:'white',padding:"20px",borderRadius:'20px'}

    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Stack sx={{width:'100%'}}  alignItems="center">
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    Overwatch Tracker
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    A simple Desktop Tracker for Overwatch
                </Typography>
                <Button
                    variant="outlined" 
                    size="large"
                    sx={{
                        width:'200px',
                        borderColor:'white',
                        marginTop:'10px',
                        borderRadius:'20px',
                        "&:hover":{
                            backgroundColor:'white',
                            color:'black',
                            borderColor:'white'
                        }
                    }}
                    startIcon={<GitHubIcon/>}
                    href="https://github.com/QuentinDelignon/ReactCatTree"
                    target="_blank"
                >
                    See on Github
                </Button>
            </Stack>
            <Stack  xs={12} spacing={3} justifyContent="space-around" alignItems="center" direction="row" style={{marginTop:'50px'}}>
                <Grid item xs={12} md={4}>
                    <img src={ovwtracker} style={{width:'100%',borderRadius:'10px'}} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <Typography variant="h3" textAlign={"center"}>
                        Track Your results in Overwatch Competitive Mode
                    </Typography>
                    <Typography variant="h5" marginTop={'50px'}>
                        I always wanted to know the historic of my games on Overwatch. Do I gradually get better or worse ?
                        Do my rating goes into a sinusoid or is it just my bias ? I wanted to find out and created a tool with Electron.JS .
                    </Typography>
                    <Typography variant="h5" marginTop={'25px'}>
                        It is essentially a desktop app that can parse data from Blizzard's (the editor) website and display a dashboard 
                        showing you your evolution. Which Blizzard does not make any effort to provide to their players. 
                        It was a really fun project to get my hands on ElectronJS and i ended up using this app for a while !
                        Unfortunately , since the release of Overwatch 2 this tool is deprecated...
                    </Typography>                 
                </Grid>
            </Stack>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}