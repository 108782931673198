import React from "react";
import { Grid , Typography , Box } from "@mui/material";

export default function BigData(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    Big Data Process Review
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Improving Data Engineering Workflow
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    As I arrived in the Big Data Team as a Data Engineer. I learned about the technologies used ,
                    how people were working and what were experienced limitation. As I worked on several projects , 
                    I dedicated time to create new Workflows and add as much automation as possible to the process.
                    The end goal was to cut by half the TAC time from a client expressing the need for specific data
                    to the moment he has access to it.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How it works
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    There were two pillars to what I was aiming at : working entirely from my computer and automating integration
                    and deployment. As I arrived , developpers would have to copy paste scripts from the repo to the cluster in order
                    to test a script or deploy it. 
                </Typography>
                <Typography variant="h5" style={{...textStyle,maxWidth:'900px'}}>
                    Efficient Prototyping
                </Typography>
                <Typography variant="h6" style={{...textStyle,maxWidth:'900px'}}>
                    I created a modified kernel usable by any employee with credentials so that
                    anyone can run a python notebook from his computer that is executed on the cluster.
                    This was very useful for both Data Engineers and Data Scientist. This enables them to
                    test a few things or do some data exploration in a few minutes.
                </Typography>
                <Typography variant="h5" style={{...textStyle,maxWidth:'900px'}}>
                    Fast and acessible Testing
                </Typography>
                <Typography variant="h6" style={{...textStyle,maxWidth:'900px'}}>
                    There were two pillars to what I was aiming at : working entirely from my computer and automating integration
                    and deployment. As I arrived , developpers would have to copy paste scripts from the repo to the cluster in order
                    to test a script or deploy it. 
                </Typography>
                <Typography variant="h5" style={{...textStyle,maxWidth:'900px'}}>
                    Automation through CI/CD
                </Typography>
                <Typography variant="h6" style={{...textStyle,maxWidth:'900px'}}>
                    There were two pillars to what I was aiming at : working entirely from my computer and automating integration
                    and deployment. As I arrived , developpers would have to copy paste scripts from the repo to the cluster in order
                    to test a script or deploy it. 
                </Typography>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}