import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useState, useLayoutEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useTheme } from '@mui/material';
import { Menu } from '@mui/material';
import { MenuItem } from '@mui/material';
import logo from '../../assets/images/logo.png'
import Contact from '../atom/Contact';

const desktop_button_style = {
    borderRadius:'10px',
    paddingLeft:'20px',
    paddingRight:'20px',
    backgroundColor:'transparent',
    marginLeft:'10px',
    "&:hover":{
        backgroundColor:'rgba(0,0,0,.2)'
    }
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

export default function MyAppBar(){
    const theme = useTheme();

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
    const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    };

    return (

        <AppBar position="sticky" sx={{backdropFilter: "blur(7px)",backgroundColor:'rgba(255,255,255,0.1)'}}>
            <Toolbar sx={{paddingTop:0,paddingBottom:0}}>
            <Link href="/" underline="none" sx={{display:{xs:'none',md:'inline'}}}>
                <img src={logo} style={{height:'30px',marginRight:'10px'}}/>
            </Link>
            <Link href="/" underline="none">
                    <Typography variant="h6" component="div" sx={{ mr: 2, display: { xs: 'none', md: 'inline' } }}>
                        Quentin Delignon
                    </Typography>
            </Link>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex',md: 'none' } , alignItems:'center' }}>
                <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                >
                <MenuIcon />
                </IconButton>
                <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                    display: { xs: 'block',md: 'none' },
                    width:'80%',
                    height:'50%'
                }}
                >
                    <MenuItem href="/Projects" component="button">
                        <Typography variant="h6" component="div">
                            Projects
                        </Typography>
                    </MenuItem>
                    <MenuItem href="/Associative" component="button">
                        <Typography variant="h6" component="div">
                            Associative Work
                        </Typography>
                    </MenuItem>
                </Menu>
                <Link href="/" underline="none">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'flex',md: 'none' } , marginLeft:'20px'}}>
                        Quentin Delignon
                    </Typography>
                </Link>
            </Box>


            
            <Box sx={{ flexDirection:"row",flexGrow: 1, display: { xs: 'none',md: 'flex' }}}>
                <Button className="pages" href="/Projects" sx={desktop_button_style} disableElevation>
                    Projects
                </Button>
                <Button className="pages" href="/Associative" sx={desktop_button_style} disableElevation>
                    associative work
                </Button>
                <Box sx={{flexGrow:4}}/>
                <Contact/>                
            </Box>
            </Toolbar>

        </AppBar>
    )
}