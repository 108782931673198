import {Stack, Typography} from '@mui/material'
import { themeOptions } from '../../utils/styles'
import Contact from '../../components/atom/Contact'

const theme = themeOptions
const style = {
    display:{
        xs:'absolute',
        md:'none'
    },
    backgroundColor: "rgba(255,255,255,0.1)",
    backdropFilter: 'blur(10px)',
    padding:'20px',
    bottom: '0',
    left:0,
    height:100,
    borderTopLeftRadius:'40px',
    borderTopRightRadius:'40px'
}

export default function Footer(){
    return (
            <Stack className="footer-container"  sx={style} justifyContent="center">
                <Contact/>
                <Typography variant="p" sx={{marginTop:'10px'}} align="center">
                    Hosted with Vercel (V1.0)
                </Typography>
            </Stack>       
    )
}