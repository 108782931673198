import { Typography } from "@mui/material";
import { Grid , Stack } from "@mui/material";
import mern from '../../../assets/images/freecn/mern.png'

export default function Integrating(){
    return (
        <Grid container alignItems="center" sx={{marginLeft:{xs:0,md:'90px'}}}>
            <Grid item xs={12} md={7}>
                <Typography variant="h3">
                    Integrating the App
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    After Deployment of the first webApp we redid the Freecn Site (not not webapp) with 
                    React because wordpress was not the best spot for custom features. After a while , we wanted to open the
                    webapp to all the users of Freecn , but we could not with the current architecture as the backend I developped back then
                    could not support it. We decided to integrate the webapp into the main website to spare some coins on hosting and DNS.
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    To do so we turned to a classic stack who proved its worth many times : MERN (Mongo Db , ExpressJS , ReactJS , NodeJS).
                    We upgraded our backend with needed features for the webapp. Once our unified Backend was ready we started to redevelop 
                    the front end of the WebApp. Why did we redo the frontend ? Because  freecn was a Bootstrap project i did while learning web 
                    development. It was ok but no so beautiful. Now that we switched to react I could use packages for some features that looked
                    much better.
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    And here we are ! A beautiful an robust powered by AI app integrated perfectly to our services.
                </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{height:'100%',margin:{xs:'30px',md:0}}}>
                    <img src={mern} style={{width:'50%'}}/>
                </Stack>
            </Grid>
        </Grid>
    )
}