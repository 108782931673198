import React from "react";
import { Grid , Typography , Box , Button, Stack} from "@mui/material";
import WebIcon from '@mui/icons-material/Web';
import GitHubIcon from '@mui/icons-material/GitHub';
import vitemaspe from '../../assets/images/vitemaspe.png'

export default function ViteMaSpe(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    Vite ma Spé !
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Helping Med School Student choosing their future
                </Typography>
                <Stack flexDirection="row" justifyContent="center" alignItems="center" style={{margin:'20px'}}>
                    <Button  
                        fullWidth
                        variant="outlined" 
                        size="large"
                        sx={{
                            margin:'10px',
                            width:'200px',
                            borderColor:'white',
                            borderRadius:'20px',
                            "&:hover":{
                                backgroundColor:'white',
                                color:'black'
                            }
                        }}
                        startIcon={<GitHubIcon/>}
                        href="https://github.com/QuentinDelignon/spe-picker"
                        target="_blank"
                    >
                        See on Github
                    </Button>
                    <Button  
                        fullWidth
                        variant="outlined" 
                        size="large"
                        sx={{
                            margin:'10px',
                            width:'200px',
                            borderColor:'darkgrey',
                            color:'darkgrey',
                            borderRadius:'20px',
                            "&:hover":{
                                backgroundColor:'darkgrey',
                                color:'black'
                            }
                        }}
                        startIcon={<WebIcon/>}
                        href="#"
                    >
                        Out of Service
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    My Girlfriend recently passed the ranking exam and has become an intern. The remaining question was where to go and what to do.
                    To help make a choice i crafted a Dashboard with which she coudl interact to help her choose what she thinks is best.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    Data Mining Phase
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    First phase of this project was to get data. I scrapped data from the official website using python and then added other sources
                    such as city positions and available spots in each type of offers.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    Dashboarding
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    I then setup a dash board using Dash which is based on Flask. I created controls to filter based on user's rank and the field
                    he would like to work in.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    Sharing to other
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    I quickly told myself that a lot of people might like this tool. I proceeded to host the app on my Raspberry PI alongside this portfolio
                    with gunicorn and nginx. I bought the domain name vite-ma-spe.fr in reference to "Vite ma Dose" a widely used Dashboard with covid statistics in france
                    made by a student.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How is it going
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    Site has been used by more than 1000 people , which is 20% of people who might have had needed it ! I had a lot
                    of adorable feedback and i am considering to set it up next year. Site is closed for now but you can find a sneek peak below.
                </Typography>
                <Stack alignItems="center" marginBottom={5}>
                    <img src={vitemaspe} style={{maxWidth:'1000px',width:"100%"}}/>
                </Stack>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}