import React from "react";
import { Grid , Typography , Box } from "@mui/material";
import YouTube from 'react-youtube';

export default function SafetyAnalysis(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    Safety Reports Analysis
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Machine Learning powered automated Safety Analytics
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    I did this project in early 2021 for a client of MP Data. The idea was to leverage machine learning in Safety Report Analysis.
                    The idea was to see trends , common factors and abnormal safety issues reported on the plant.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How it works
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    Every report had pre determined features (date , injury and so on) and a free text part. The idea is that most of contextual
                    information was contained here. I cleaned the data thoroughly of mispelled words and typos. I then used a Deep Learning model pre trained
                    on french texts to extract information from the free text part. This data was then processed in several ways. A clustering algorithm which aimed to
                    create different groups among the reports and determined a notion of distance which was very useful to detect abnormal issues. A
                    lot of work has then been put into data analysis and Dashboarding to get stunning visuals to easily pinpoint specific issues. 
                    The client was very happy of our work , the dashboard was very useful to orient future audits of safety and process. It also
                    helped to see trends and take numerous actions to provide safer environments to their workers.
                </Typography>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}