import React from "react";
import { Grid , Typography , Box, List, ListItem, ListItemText } from "@mui/material";
import YouTube from 'react-youtube';

export default function VirtualSensing(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    Virtual Sensing
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Deep Learning packed alternatives for costly measurements
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                   Virtual Sensing is the applications of statistical methods to sensor based data.There are several use cases:
                </Typography>
                    <Typography variant="h6" style={{...textStyle,paddingLeft:'30px'}}>
                        Predict the features that was only available during tests from the features available in production. 
                        This enables richer data feedback from deployed systems
                        <br/>
                        <br/>
                        Build a numeric twin of the system to easily detect sensor failures or unexpected behaviors
                        <br/>
                        <br/>
                        Replace a costly sensor by several cheap sensors which measures correlated measures with which we predict the actual measurement
                        <br/>
                        <br/>
                        Create a cheap alternative to simulation software whom licenses are usually expensives
                    </Typography>
                <Typography variant="h4" style={textStyle}>
                    What we did
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    The kind of algorithm to use depends heavily on the structure of the available data and the use case we are in. 
                    In our case we used a Deep Graph Network. Our data was represented and processed as a Graph. We then used Convolution
                    operations to create an estimator of steate of a node based on its neighbors. We had very good results. 
                    <br/>
                    A simple disclaimer : these algorithm are very performant but can , in some cases, be unreliable. They are very prone to
                    overfitting and black swans (exogen events who changes drastically the distribution of new data , like the covid pandemic).
                    It is necessary to assess the factors who affects the measure and try to make a dataset covering as much ground as possible.
                </Typography>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}