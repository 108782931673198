import ISM from "../assets/images/experience/sainte-marie.png";
import AM from "../assets/images/experience/am.png";
import freecn from "../assets/images/experience/freecn.png";
import mpdata from "../assets/images/experience/mpdata.png";
import SAFRAN from "../assets/images/experience/safran.png";
import freecn_full from "../assets/images/projects/freecn.png";
import gymcam from "../assets/images/projects/gymcam.png";
import cattree from "../assets/images/projects/cattree.png";
import ovw from "../assets/images/projects/ovw.png";
import ACE from "../assets/images/projects/ACE.png";
import slabflow from "../assets/images/projects/SLABFLOW.png";
import big_data from "../assets/images/projects/BIG_DATA.png";
import safety from "../assets/images/projects/safety.png";
import virtual_sensing from "../assets/images/projects/virtual_sensing.png";
import computer from "../assets/images/projects/computer.png";
import vitemaspe from "../assets/images/projects/vitemaspe.png";
import daveo from '../assets/images/experience/daveo.png'

export const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                     magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo 
                     consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                     Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum`;

export const experience = [
  {
    title: "Engineering Prep School",
    date: "2015-2017",
    subtitle: "CPGE Sainte Marie",
    location: "Paris",
    body: "In France , in order to attend best engineering schools,\
                you need to pass a ranking exam after 2 years of formation. \
                My majors were mathematics and physics while my minors were Engineering and Computer Science",
    img: ISM,
    color: 'black'
  },
  {
    title: "Arts & Métiers Engineering School",
    date: "2017-2020",
    subtitle: "Arts & Métiers ParisTech",
    location: "Lille",
    body: "Master of Science in Engineering with specialization in Mecatronics and AI.\
             This formation has been a chance for me to study classic industrial process such as \
             forge , fundry , material study , wood work or plasturgy. I also studied heavily \
             subjects core to 4.0 Industry such as IOT , Big Data and Machine Learning.\
             Finally I decided to specialize in Ai through my specialization semester ,\
             my internship and various projects I have participated to during these years",
    img: AM,
    color: '#80276C'
  },
  {
    title: "Deep Learning Researcher Intern",
    date: "2019-2020",
    subtitle: "Safran Tech",
    location: "Paris",
    body: "I joined Safran's AI research pole for 6 month as my end of studies internship.\
              I worked on LiDar Data Supersampling. The goal was to densify LiDar Data, which are\
              3D cartographies of the environment of the cars. The project was very interesting, allowing\
              me to learn from eminent scientists in the field and understand the world and codes of\
              fundamental research. This Internship has helped me throughout my carreer when a project is\
              in its early stages.",
    img: SAFRAN,
    color: '#083E81'
  },
  {
    title: "Benevolent Data Scientist & FullStack Developer",
    date: "2020-2022",
    subtitle: "Freecn Association",
    location: "Toulouse",
    body: "Freecn is a non profitable association which aims to provide free tools for Med Schools students.\
            I started as a Ai Engineer to create some Ai text ingestion which generated questions\
            semi-automatically . I then moved on as a Fullstack Developer for web and mobile application. You can\
            find more info about this lovely project in the 'Associative Work' Tab",
    img: freecn,
    color: '#F5B9C2'
  },
  {
    title: "Junior Data Consultant",
    date: "2021-2023",
    subtitle: "MP DATA Group",
    location: "Paris",
    body: "MP Data is a Consulting Company specialized in Data. I arrived in Fevruary 2020 as a Data Consultant :\
         a consultant who can work on different types of projects related to data in the Industry. As such , I took on the roles\
         of Data Scientist , Operations Research Engineer, Deep Learning Scientist and Data Engineer",
    img: mpdata,
    color: '#000054'
  },
  {
    title: 'Data Consultant',
    date : "Since 2023",
    subtitle: "Daveo - Magellan Partners",
    location : "Lille",
    body : "Daveo is a Consulting Company specialised in Cloud and Product Managment. It is part of the Group Magellan Partners.\
          I arrived in March 2023 as a Data Scientist and Engineer. I worked on different Projects that were more \
          Cloud and DataOps oriented. Acquiring critical skillsets in theses fields in the process",
    img : daveo,
    color : "#00B9FF"
  }
];

export const projects = [
  {
    img: big_data,
    title: "Big Data Process Reviews",
    body: "Improving Data Engineering processes",
    tags: [
      "Spark",
      "CI/CD",
      "Python",
      "Consulting",
      "Private Cloud",
      "Data Engineering",
    ],
    href: "/BigData",
  },
  {
    img: ACE,
    title: "ACE",
    body: "Improving Crane Automation",
    tags: ["Operations Research", "C++", "Supply Chain", "Automation"],
    href: "/Ace",
  },
  {
    img: slabflow,
    title: "Slabflow",
    body: "Travel optimization for raw materials",
    tags: ["Operations Research", "C#", "Supply Chain", "Automation"],
    href: "/SlabFlow",
  },
  {
    img: vitemaspe,
    title: "Vite ma Spé !",
    body: "helping med school students onto the right path",
    tags: ["Dashboarding", "Python", "Data Mining"],
    href: "/ViteMaSpe",
  },
  {
    img: freecn_full,
    title: "Freecn.io",
    body: "Med School Accessible to everyone",
    tags: [
      "Python",
      "Deep Learning",
      "NLP",
      "FullStack",
      "React",
      "React Native",
      "Electron",
      "Cloud",
      "AWS",
    ],
    href: "/Associative",
  },
  {
    img: computer,
    title: "Virtual Sensing",
    body: "Feasible virtual alternatives to costly physical measurements",
    tags: ["Digital Twin", "Python", "Deep Learning", "Hybrid Models"],
    href: "/VirtualSensing",
  },
  {
    img: safety,
    title: "Unsupervised Safety Analysis",
    body: "Automate the analysis of safety report in industrial context",
    tags: [
      "Python",
      "Machine Learning",
      "Natural Language Processing",
      "Unsupervised Learning",
      "Dashboarding",
    ],
    href: "/SafetyAnalysis",
  },
  {
    img: virtual_sensing,
    title: "Mask Segmentation",
    body: "Precise object detection for process automation",
    tags: ["Computer Vision", "Deep Learning", "Python"],
    href: "/MaskRCNN",
  },
  {
    img: ovw,
    title: "Overwatch Tracker",
    body: "Track your progression easily",
    tags: ["Desktop Development", "React", "ElectronJS"],
    href: "/Overwatch-Tracker",
  },
  {
    img: gymcam,
    title: "GymCam",
    body: "Focus on your session",
    tags: [
      "Computer Vision",
      "Deep Learning",
      "Python",
      "React Native",
      "React",
      "Cloud",
      "GCP",
    ],
    href: "/GymCam",
  },
  {
    img: cattree,
    title: "Cat Tree",
    body: "Keep your focus , get work done and earn rewards",
    tags: ["Xamarin.IOS", "C#", "React Native"],
    href: "/CatTree",
  },
];
