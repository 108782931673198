import { Typography } from "@mui/material";
import { Grid , Stack } from "@mui/material";
import freecn from '../../../assets/images/freecn_logo.png'

export default function Project(){
    return (
        <Grid container sx={{marginLeft:{xs:0,md:'70px'},marginRight:{xs:0,md:'70px'}}} alignItems="center">
            <Grid item xs={12} md={7}>
                <Typography variant="h3" sx={{marginBottom:'20px'}}>
                    The Project
                </Typography>
                <Typography variant="h5" component="div" sx={{textAlign:'justify'}}>
                    Med School is expensive ... In addition to tuiton fees , you ought to pay books , a roof over your head and food to keep you going.
                    Furthermore your classic Med School Starter does not include Exam Practice. In France, you choose your specialty thanks to a ranking exam 
                    which presents itself as a multiple choice quizz.
                </Typography>
                <Typography variant="h5" component="div" sx={{marginTop:'20px',textAlign:'justify'}}>
                    You have two choices concerning that matter , SIDES , which is a free website made by academics with a lot of questions. The issue is that a lot of them
                    are outdated or any other platform , more beautiful , with curated questions , user statistics etc... Problem is , the price ! Subscription for these sites 
                    costs approximately 300€ a year .
                </Typography>
                <Typography variant="h5" component="div" sx={{marginTop:'20px',textAlign:'justify'}}>
                    We , at Freecn , strongly believes that everybody deserves equal chances to succeed. This why our Founder created the association : to make every participant 
                    to this ranking exam as equal as possible in terms of means of learning.
                </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{height:'100%',margin:{xs:'30px',md:0}}}>
                    <img src={freecn} style={{width:"50%"}}/>
                </Stack>
            </Grid>
        </Grid>
    )
}