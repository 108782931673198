import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { AttentionSeeker, Fade, Slide, Zoom } from "react-awesome-reveal";
import bulb from "../assets/images/homepage/bulb-dynamic-gradient.png";
import computer from "../assets/images/homepage/computer-dynamic-gradient.png";
import cup from "../assets/images/homepage/cup-dynamic-gradient.png";
import headphone from "../assets/images/homepage/headphone-dynamic-gradient.png";
import paintbrush from "../assets/images/homepage/paint-brush-dynamic-gradient.png";
import Contact from "../components/atom/Contact";

export default function HomePage() {
  return (
    <Box
      sx={{
        minHeight: {
          xs: window.innerHeight - 60,
          md: window.innerHeight - 60,
        },
        padding: "20px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{ flex: 1 }}
      >
        <Grid item xs={12} md={5}>
          <Slide duration={2000} direction="down" triggerOnce>
            <Fade duration={2000} triggerOnce>
              <Typography
                variant="h1"
                sx={{ color: "white", fontSize: { xs: 60, md: 100 } }}
              >
                Hi , I am Quentin !
              </Typography>
            </Fade>
          </Slide>
          <Fade cascade delay={500} triggerOnce>
            <Typography variant="h3" sx={{ color: "white", marginTop: "10px" }}>
              I am a Data Engineer fond of AI , Blockchain and Web Development
            </Typography>
            <Typography variant="h5" sx={{ color: "white", marginTop: "10px" }}>
              Welcome to my portfolio, feel free to navigate among my various
              projects and learn more about me. Do not hesitate to contact me by
              any mean you like
            </Typography>
          </Fade>
          <Slide delay={500} direction="up" triggerOnce>
            <Fade delay={500} triggerOnce>
              <Box sx={{ marginTop: 2, display: { xs: "none", md: "flex" } }}>
                <Contact />
                <Box sx={{ flexGrow: 1 }} />
              </Box>
            </Fade>
          </Slide>
        </Grid>
        <Zoom delay={500} duration={1000} triggerOnce>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: { xs: "none", md: "flex" },
              width: "20vw",
              height: "10px",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={headphone}
              style={{
                position: "absolute",
                width: "300px",
                height: "300px",
                transform: "translateX(-150px) translateY(-50px)",
              }}
            />
            <img
              src={bulb}
              style={{
                position: "absolute",
                width: "200px",
                height: "200px",
                transform: "translateX(160px) translateY(-40px) rotate(-75deg)",
              }}
            />
            <img
              src={cup}
              style={{
                position: "absolute",
                width: "150px",
                height: "150px",
                transform: "translateX(-150px) translateY(150px)",
              }}
            />
            <img
              src={paintbrush}
              style={{
                position: "absolute",
                width: "200px",
                height: "200px",
                transform: "translateX(120px) translateY(130px) rotate(-75deg)",
              }}
            />
            <img
              src={computer}
              style={{
                position: "absolute",
                width: "400px",
                height: "400px",
                transform: "translateX(0px) translateY(0px)",
              }}
            />
          </Grid>
        </Zoom>
      </Grid>
      <Box
        sx={{
          display:{xs:'none', md: "flex"},
          alignItems: "center",
          flexDirection: "column",
          marginBottom: "40px",
        }}
      >
        <AttentionSeeker delay={3000}>
          <p
            style={{
              color: "dimgray",
              fontSize: "24px",
              letterSpacing: "6px",
              margin: "auto",
              marginBottom: -10,
            }}
          >
            SCROLL
          </p>
          <KeyboardArrowDownIcon
            sx={{ color: "dimgray", fontSize: "64px", margin: "auto" }}
          />
        </AttentionSeeker>
      </Box>
    </Box>
  );
}
