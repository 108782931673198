import github from '../../assets/images/contact/github.svg'
import linkedin from '../../assets/images/contact/linkedin.svg'
import medium from '../../assets/images/contact/medium.svg'
import mail from '../../assets/images/contact/maildotru.svg'
import phone from '../../assets/images/contact/call_FILL1_wght600_GRAD0_opsz48.svg'
import { Stack } from '@mui/system'
import { Link } from '@mui/material'


export default function Contact(){
    return (
        <Stack direction="row" sx={{alignItems:'center',justifyContent:'space-between',flex:1,minWidth:'230px'}}>
            <Link href="https://github.com/QuentinDelignon" target="_blank" className="card-hoverable">
            <img src={github} style={{height:30,width:30}}/>
            </Link>
            <Link href="https://www.linkedin.com/in/quentin-delignon/" target="_blank" className="card-hoverable">
                <img src={linkedin} style={{height:30,width:30}}/>
            </Link>
            <Link href="https://medium.com/@quentin.delignon" target="_blank" className="card-hoverable">
                <img src={medium} style={{height:30,width:30}}/>
            </Link>
            <Link href="mailto:quentin.delignon@gmail.com" target="_blank" className="card-hoverable">
                <img src={mail} style={{height:30,width:30}}/>
            </Link>
            <Link href="tel:+33632900443" target="_blank" className="card-hoverable">
                <img src={phone} style={{height:30,width:30}}/>
            </Link>
        </Stack> 
    )
}