import { Grid, Typography } from "@mui/material";
import ProjectCard from "../components/templates/ProjectCard";
import { projects } from "../utils/consts";
import {Fade} from "react-awesome-reveal";

export default function Projects(){

    
        return (
            <Grid container sx={{padding:'20px'}} alignItems="center" justifyContent="center">
                <Grid item xs={12} sx={{marginBottom:'30px'}}>
                    <Typography variant="h1" sx={{textAlign:'center'}}>
                        Projects
                    </Typography>
                </Grid>
                <Grid container spacing={3} justifyContent="center">
                    {projects.map((project , index)=>{
                        return(  
                            <Grid item xs={12} sm={6} md={4} xl={3}>
                                <Fade duration={1000} triggerOnce>
                                <ProjectCard project={project}/>
                                </Fade>
                            </Grid>
                            
                        )
                    })}
                </Grid>     
            </Grid>
        )
}