import { Typography } from "@mui/material";
import { Grid , Stack } from "@mui/material";
import NEXT from '../../../assets/images/freecn/next.png'
 
export default function NextSteps(){
    return (
        <Grid container sx={{marginLeft:{xs:0,md:'90px'}}} alignItems="center">
            <Grid item xs={12} md={7}>
                <Typography variant="h3">
                    Next Steps for the Tech Team
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    Now that we have build solid foundations we can move on to other platforms !
                    We are going to make a new IOS/Android with React native and an elecron App that will
                    be able to run on MacOS and Windows . Both these Apps will have offline support which will
                    enable students to work in public transports or on the country side !
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    The idea here is to develop the react native app with all its feature and reuse it 
                    afterwards as core into the electron bundle , that way we only have to code one app and
                    do some finishing touches on the electron part , sparing us a lot of developping time
                </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{height:'100%',margin:{xs:'30px',md:0}}}>
                    <img src={NEXT} style={{maxWidth:'70%'}}/>
                </Stack>
            </Grid>
        </Grid>
    )
}