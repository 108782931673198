import { Typography } from "@mui/material";
import { Grid , Stack } from "@mui/material";
import AI from '../../../assets/images/freecn/AI.png'
 
export default function MakeAI(){
    return (
        <Grid container sx={{marginLeft:{xs:0,md:'90px'},paddingBottom:'30px'}} alignItems="center">
            <Grid item xs={12} md={7}>
                <Typography variant="h3">
                    Making an AI
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    Since the exam is a multiple choice question , we needed to create our own question pool
                    for our users to train on. Back then everything was manual , the team would write down new questions
                    into an excel file that the head of the team would copy paste into the db.
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    I created an AI that used MedSchool lessons (plain text) to extract interesting sentences.
                    We can then use the result of our AI to make form auto completion. The sentences extracted are potential
                    choices for a question.
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    The Ai is a pipeline composed of Deep Learning and Machine Learning Components. We split the input text into sentences
                    and use them as input for CAMENBERT , a french version of BERT. It encodes the sentences into vectors wich are then used
                    into a TextRank Algorithm. Said algorithm attributes a relevency score to each sentence we then filter the sentences to keep 70% of them. 
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    This Algorithm is at the core of the performances enabled by the web app we will discuss in the next page.
                </Typography>

            </Grid>
            <Grid item xs={12} md={5} sx={{margin:{xs:0,md:0}}}>
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{width:{xs:'100%',md:'70%'},marginTop:{xs:0,md:'20px'}}}>
                    <img src={AI} style={{width:'100%'}}/>
                </Stack>
            </Grid>
        </Grid>
    )
}