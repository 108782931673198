import { faJsSquare, faPython } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { AttentionSeeker } from "react-awesome-reveal";
import { EffectCards, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import amazonaws from "../assets/images/Skills/amazonaws.svg";
import sql from "../assets/images/Skills/amazondynamodb.svg";
import airflow from "../assets/images/Skills/apacheairflow.svg";
import hadoop from "../assets/images/Skills/apachehadoop.svg";
import kafka from "../assets/images/Skills/apachekafka.svg";
import spark from "../assets/images/Skills/apachespark.svg";
import ios from "../assets/images/Skills/appstore.svg";
import beam from "../assets/images/Skills/beam-logo-3-color-nameless.svg";
import cplusplus from "../assets/images/Skills/cplusplus.svg";
import csharp from "../assets/images/Skills/csharp.svg";
import databricks from "../assets/images/Skills/databricks.svg";
import dbt from "../assets/images/Skills/dbt.svg";
import docker from "../assets/images/Skills/docker.svg";
import fastapi from "../assets/images/Skills/fastapi.svg";
import firebase from "../assets/images/Skills/firebase.svg";
import flask from "../assets/images/Skills/flask.svg";
import githubActions from "../assets/images/Skills/githubactions.svg";
import bigquery from "../assets/images/Skills/google-bigquery-logo-1.svg";
import gcp from "../assets/images/Skills/googlecloud.svg";
import android from "../assets/images/Skills/googleplay.svg";
import grafana from "../assets/images/Skills/grafana.svg";
import heroku from "../assets/images/Skills/heroku.svg";
import html from "../assets/images/Skills/html5.svg";
import kubernetes from "../assets/images/Skills/kubernetes.svg";
import azure from "../assets/images/Skills/microsoftazure.svg";
import mongo from "../assets/images/Skills/mongodb.svg";
import nodeJS from "../assets/images/Skills/nodedotjs.svg";
import pandas from "../assets/images/Skills/pandas.svg";
import plotly from "../assets/images/Skills/plotly.svg";
import powerbi from "../assets/images/Skills/powerbi.svg";
import pytorch from "../assets/images/Skills/pytorch.svg";
import react from "../assets/images/Skills/react.svg";
import sklearn from "../assets/images/Skills/scikitlearn.svg";
import tableau from "../assets/images/Skills/tableau.svg";
import tensorflow from "../assets/images/Skills/tensorflow.svg";
import terraform from "../assets/images/Skills/terraform.svg";
import SkillAccordion from "../components/templates/SkillAccordion";
import SkillCard from "../components/templates/SkillCard";
import pubsub from "../assets/images/Skills/google-cloud-pub-sub.svg";
import go from "../assets/images/Skills/go.svg";
import rust from "../assets/images/Skills/rust.svg";
import vyper from "../assets/images/Skills/vyper.svg";
import solidity from "../assets/images/Skills/solidity.svg";

import SkillHover from "../components/molecules/SkillHover";
import Carousel from "react-material-ui-carousel";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import SkillCardAlternate from "../components/templates/SkillCardAlternate";

export default function SkillsPage() {
  return (
    <Box sx={{ alignItems: "center" }}>
      <Typography
        variant="h1"
        sx={{
          textAlign: "center",
          fontSize: { xs: 60, md: 100 },
          marginBottom: 5,
        }}
      >
        Skillset
      </Typography>

      <Box sx={{ width: { md: "60%" }, margin: { xs: "10px", md: "auto" } }}>
        <Carousel
          cycleNavigation
          navButtonsAlwaysVisible
          fullHeightHover
          swipe
          indicators
          animation="slide"
          autoPlay
        >
          <SkillAccordion title="Data Engineering">
            <SkillCard
              name={"PySpark"}
              rating={4}
              icon={<img src={spark} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"AirFlow"}
              rating={5}
              icon={<img src={airflow} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Beam"}
              rating={1}
              icon={<img src={beam} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"DBT"}
              rating={3}
              icon={<img src={dbt} className="skill-logo-alternate" />}
            />
            <SkillCard
              name="Databricks"
              rating={3}
              icon={<img src={databricks} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
          <SkillAccordion title="Data Science">
            <SkillCard
              name={"Pytorch"}
              rating={4}
              icon={<img src={pytorch} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Tensorflow"}
              rating={3}
              icon={<img src={tensorflow} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"SkLearn"}
              rating={4}
              icon={<img src={sklearn} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Pandas"}
              rating={5}
              icon={<img src={pandas} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
          <SkillAccordion title="Languages & Development">
            <SkillCard
              name={"Python"}
              rating={5}
              icon={
                <img
                  src="https://simpleicons.org/icons/python.svg"
                  style={{ filter: "invert(1)" }}
                  className="skill-logo-alternate"
                />
              }
            />
            <SkillCard
              name={"JavaScript"}
              rating={4}
              icon={
                <img
                  src="https://simpleicons.org/icons/javascript.svg"
                  style={{ filter: "invert(1)" }}
                  className="skill-logo-alternate"
                />
              }
            />
            <SkillCard
              name={"TypeScript"}
              rating={4}
              icon={
                <img
                  src="https://simpleicons.org/icons/typescript.svg"
                  style={{ filter: "invert(1)" }}
                  className="skill-logo-alternate"
                />
              }
            />
            <SkillCard
              name={"C#"}
              rating={3}
              icon={<img src={csharp} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"C++"}
              rating={2}
              icon={<img src={cplusplus} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
          <SkillAccordion title="Cloud">
            <SkillCard
              name={"AWS"}
              rating={2}
              icon={<img src={amazonaws} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"GCP"}
              rating={4}
              icon={<img src={gcp} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Azure"}
              rating={2}
              icon={<img src={azure} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
          <SkillAccordion title="DevOps">
            <SkillCard
              name={"Docker"}
              rating={5}
              icon={<img src={docker} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"CICD"}
              rating={4}
              icon={
                <img src={githubActions} className="skill-logo-alternate" />
              }
            />
            <SkillCard
              name={"GIT"}
              rating={5}
              icon={
                <img
                  src="https://simpleicons.org/icons/git.svg"
                  style={{ filter: "invert(1)" }}
                  className="skill-logo-alternate"
                />
              }
            />
          </SkillAccordion>
          <SkillAccordion title="Backend Development">
            <SkillCard
              name={"Flask"}
              rating={5}
              icon={<img src={flask} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"FastAPI"}
              rating={5}
              icon={<img src={fastapi} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"ExpressJS"}
              rating={3}
              icon={<img src={nodeJS} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
          <SkillAccordion title="Frontend Development">
            <SkillCard
              name={"React"}
              rating={4}
              icon={<img src={react} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Vue"}
              rating={3}
              icon={
                <img
                  src="https://static-00.iconduck.com/assets.00/vue-icon-2048x1766-20srmg4c.png"
                  className="skill-logo-alternate"
                />
              }
            />
            <SkillCard
              name={"HTML"}
              rating={4}
              icon={<img src={html} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
          <SkillAccordion title="Mobile Development">
            <SkillCard
              name={"React Native"}
              rating={3}
              icon={<img src={react} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Android (Java)"}
              rating={2}
              icon={<img src={android} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Xamarin.IOS (C#)"}
              rating={2}
              icon={<img src={ios} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
          <SkillAccordion title="Infrastructure">
            <SkillCard
              name={"Kubernetes"}
              rating={4}
              icon={<img src={kubernetes} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Terraform"}
              rating={2}
              icon={<img src={terraform} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Helm"}
              rating={3}
              icon={
                <img
                  src="https://simpleicons.org/icons/helm.svg"
                  style={{ filter: "invert(1)" }}
                  className="skill-logo-alternate"
                />
              }
            />
            <SkillCard
              name={"Datadog"}
              rating={4}
              icon={
                <img
                  src="https://simpleicons.org/icons/datadog.svg"
                  style={{ filter: "invert(1)" }}
                  className="skill-logo-alternate"
                />
              }
            />
          </SkillAccordion>
          <SkillAccordion title="Blockchain">
            {/*<SkillCard
            name={"GO"}
            rating={0}
            icon={<img src={go} className="skill-logo-alternate" />}
          />
          <SkillCard
            name={"Rust"}
            rating={0}
            icon={<img src={rust} className="skill-logo-alternate" />}
          />*/}
            <SkillCard
              name={"Solidity"}
              rating={2}
              icon={<img src={solidity} className="skill-logo-alternate" />}
            />
            <SkillCard
              name={"Vyper"}
              rating={1}
              icon={<img src={vyper} className="skill-logo-alternate" />}
            />
          </SkillAccordion>
        </Carousel>
      </Box>
      <Divider
        sx={{
          marginLeft: "10%",
          marginRight: "10%",
          backgroundColor: "gray",
          marginTop: { xs: 5, md: 10 },
          marginBottom: { xs: 5, md: 10 },
        }}
      />
    </Box>
  );
}

function optionalSkills() {
  return (
    <SkillHover title="DataBases & Data Analysis">
      <SkillCardAlternate
        name={"Tableau"}
        level="lower-intermediate"
        icon={<img src={tableau} className="skill-logo-alternate" />}
      />
      <SkillCardAlternate
        name={"PowerBI"}
        level="intermediate"
        icon={<img src={powerbi} className="skill-logo-alternate" />}
      />
      <SkillCardAlternate
        name={"Grafana"}
        level="profficent"
        icon={<img src={grafana} className="skill-logo-alternate" />}
      />
      <SkillCardAlternate
        name={"Dash"}
        level="profficent"
        icon={<img src={plotly} className="skill-logo-alternate" />}
      />
      <SkillCardAlternate
        name={"SQL"}
        level="profficent"
        icon={<img src={sql} className="skill-logo-alternate" />}
      />
      <SkillCardAlternate
        name={"NoSQL"}
        level="intermediate"
        icon={<img src={mongo} className="skill-logo-alternate" />}
      />
      <SkillCardAlternate
        name={"Hadoop"}
        level="lower-intermediate"
        icon={<img src={hadoop} className="skill-logo-alternate" />}
      />
      <SkillCardAlternate
        name={"Kafka"}
        level="intermediate"
        icon={<img src={kafka} className="skill-logo-alternate" />}
      />
    </SkillHover>
  );
}

const styles = {
  card: {
    height: "80px",
    width: "80px",
  },
};
