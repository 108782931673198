import React from "react";
import { Grid , Typography , Box, Button , Stack } from "@mui/material";
import YouTube from 'react-youtube';
import GitHubIcon from '@mui/icons-material/GitHub';
import img0 from '../../assets/images/cattree/0.png'
import img1 from '../../assets/images/cattree/1.png'
import img2 from '../../assets/images/cattree/2.png'
import img3 from '../../assets/images/cattree/3.png'
import img4 from '../../assets/images/cattree/4.png'
import img5 from '../../assets/images/cattree/5.png'

export default function CatTree(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}} alignItems="center" justifyContent="center">
            <Grid item xs={12} alignItems="center">
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    The Cat Tree App
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    The App that rewards you for getting things done
                </Typography>
                <Stack alignItems="center" style={{margin:'20px'}}>
                    <Button  
                        fullWidth
                        variant="outlined" 
                        size="large"
                        sx={{
                            width:'200px',
                            borderColor:'white',
                            borderRadius:'20px',
                            "&:hover":{
                                backgroundColor:'white',
                                color:'black'
                            }
                        }}
                        startIcon={<GitHubIcon/>}
                        href="https://github.com/QuentinDelignon/ReactCatTree"
                        target="_blank"
                    >
                        See on Github
                    </Button>
                </Stack>
                
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    During my job search, I was looking for new projects to learn new skills.
                    I aimed to know the basics of Fullstack Development and mobile development.
                    I already had developped an Audio Guide app back in school for an event.
                    It was Android only so I wished to learn IOS development.
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    My Girlfriend was in Med School and she wanted a nice way to track
                    the hours she spend working. I told myself i could make an app that would
                    track the time she spends working and reward her for it.
                </Typography>
                    
                
                <Typography variant="h4" style={textStyle}>
                    How it Works
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    Since I had no MAC, I decided to use XAMARIN (on C#) to develop the App and a Mac OS VM
                    to compile It. The App would be quite simple :  the user would launch
                    a timer and get some work done during its duration. At the end of the timer,
                    the user is rewarded with coins that he or she can spend in a integrated shop.
                    said shop would be full of images of one of two types : cats and backgrounds. Finally
                    a screen is customizable to display the rewards earned through hard work.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How it Looks
                </Typography>
            </Grid>
            <Grid spacing={3} container style={{alignSelf:'center',marginLeft:'auto',marginRight:'auto'}} justifyContent="center">
                <Grid item xs={6} md={4} md={2}>
                    <img src={img1} style={{width:'100%'}} />
                </Grid>
                <Grid item xs={6} md={4} md={2}>
                    <img src={img0} style={{width:'100%'}} />
                </Grid>
                <Grid item xs={6} md={4} md={2}>
                    <img src={img3} style={{width:'100%'}} />
                </Grid>
                <Grid item xs={6} md={4} md={2}>
                    <img src={img4} style={{width:'100%'}} />
                </Grid>
                <Grid item xs={6} md={4} md={2}>
                    <img src={img5} style={{width:'100%'}} />
                </Grid>
            </Grid>
            
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}