import { Box,Grid, Stack,Typography} from "@mui/material";
import React from "react";
import notfound from "../assets/images/404.png"

export default function NotFound(){
    return (
        <div style={{height:window.innerHeight-145,alignItems:'stretch',flexDirection:'column'}}>
        <Stack sx={{flexDirection:'column',padding:'10px',fontSize:{xs:60,md:100},flexShrink:1}} alignItems="center">
            <img src={notfound} style={{width:'80%',maxWidth:'700px'}}/>
            <Typography variant="h4" textAlign="center">
                We did not find the page you are looking for ...
            </Typography>
        </Stack>
        <Box style={{flexDirection:'column',flexGrow:3}}/>
        </div>
    )
}

