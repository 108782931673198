import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Carousel from "react-material-ui-carousel";

import Project from "../components/molecules/freecn/Project";
import MakeAI from "../components/molecules/freecn/MakingAi";
import Building from "../components/molecules/freecn/Building";
import Integrating from "../components/molecules/freecn/Integrating";
import NextSteps from "../components/molecules/freecn/NextSteps";

export default function AssociativeWorkPage(){
    return (
        <>
            <Typography variant="h1" sx={{textAlign:'center',fontSize:{xs:60,md:100},marginTop:'10px'}}>
                Associative Work
            </Typography>
            <Typography variant="h5" sx={{textAlign:"center"}}>
                2 years Ago I Joined :
            </Typography>
            <Typography variant="h3" sx={{textAlign:"center"}}>
                Freecn
            </Typography>
            <Typography variant="h5" sx={{textAlign:"center",marginBottom:'40px'}}>
                An association which aims to provide Med School students free tools to learn
            </Typography>
            <Box sx={{width:'100%',display:{xs:'none',md:'block'}}}>
                <Carousel 
                    navButtonsAlwaysVisible 
                    fullHeightHover 
                    autoPlay={false} 
                    animation="slide" 
                    duration={500}
                    cycleNavigation={false}
                    navButtonsProps={{style:{height:"60px",width:"60px"}}}
                    >
                    {[
                        <Project/>,
                        <MakeAI/>,
                        <Building/>,
                        <Integrating/>,
                        <NextSteps/>
                    ].map( (item,i)=>item)
                    }
                </Carousel>
            </Box>
            <Stack sx={{margin:'20px',display:{xs:'block',md:'none'}}}>
                <Project/>
                <MakeAI/>
                <Building/>
                <Integrating/>
                <NextSteps/>
            </Stack>
        </>
    )
}