import { Typography } from "@mui/material";
import { Grid , Stack} from "@mui/material";
import flask from '../../../assets/images/freecn/flaskApp.png'

export default function Building(){
    return (
        <Grid container sx={{marginLeft:{xs:0,md:'90px'}}} alignItems="center">
            <Grid item xs={12} md={7}>
                <Typography variant="h3">
                    Making a Web App for the Team
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    Once the AI finished I created a small webapp so that the team could write questions more easily and
                    most importantly so that their question can be send to the db automatically once double checked by another member.
                    I also added the possibility to have the forms pre filled by the AI so that the user could modify the text a bit to
                    create a question and save a lot of tedious time on the process.
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    The webApp was written with Flask (python) for the backend and Bootstrap (HTML/CSS) for the frontend. We handled user
                    connection using AWS Cognito and hosted it with AWS EC2.
                </Typography>
                <Typography variant="h5" sx={{marginTop:'10px',textAlign:'justify'}}>
                    The webapp enabled us to triple the number of questions in our pool in just a few months ! from 1000 question in 6 months
                    of work to 3000 3 months later.
                </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{height:'100%',margin:{xs:'30px',md:0}}}>
                    <img src={flask} style={{width:'50%'}}/>
                </Stack>
            </Grid>
        </Grid>
    )
}