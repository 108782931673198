import { Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, Link, Stack, Typography } from "@mui/material";
import { Box  } from "@mui/system";
import blob from '../../assets/images/blob.svg'

export default function ProjectCard(props){

    const getTags = (element)=><Chip variant="outlined" label={element} sx={{backgroundColor:'transparent',margin:'5px'}} /> ;

    const cardStyle = {
        borderRadius: '1rem',
        boxShadow: 'none',
        position: 'relative',
        backgroundColor:"white",
        height:'300px',
        overflow : 'clip',
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '100%',
          height: '100%',
          bottom: 0,
          zIndex: 1,
          background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
        }
    }
    const contentStyle =  {
        position: 'absolute',
        zIndex: 2,
        bottom: 0,
        flex:2,
        flexDirection:'row',
        padding:2,
      }

    return (
        <Link href={props.project.href ? props.project.href : "#"}>
        <Card 
            raised 
            sx={cardStyle}
        >
            <CardMedia>
                <img alt="project" src={props.project ? props.project.img : blob} style={{objectFit:'cover',borderRadius:'0px',height:'300px',width:'100%'}}/>
            </CardMedia>
            <Box  py={3} px={2} sx={contentStyle}>
                <Typography variant="h5">
                    {props.project ? props.project.title : "Sample Title"}
                </Typography>
                
                <Typography variant="p" sx={{textAlign:'justify'}}>
                    {props.project ? props.project.body : "Sample Body"}
                </Typography>
                <Grid container direction="row" spacing={1} sx={{marginTop:'10px',marginBottom:'10px'}} justifyContent="flex-start">
                    {props.project ? props.project.tags.map(getTags) : ["sample tag 1", "sample tag 2","sample tag 2"].map(getTags)}
                </Grid>                
            </Box>
        </Card>
        </Link>
    )
}