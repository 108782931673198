import * as React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import {
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import { Stack, Card } from "@mui/material";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from "@mui/icons-material/Business";
import SchoolIcon from "@mui/icons-material/School";

export default function CustomizedTimelineItemWide({
  color,
  num,
  title,
  date,
  subtitle,
  location,
  body,
  img,
}) {
  const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  React.useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <TimelineItem>
      <TimelineOppositeContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Stack
            direction={num % 2 == 0 ? "row-reverse" : "row"}
            alignItems={"center"}
            justifyContent={num % 2 == 0 ? "flex-start" : "flex-start"}
          >
            {num < 2 ? (
              <SchoolIcon
                sx={{
                  marginLeft: num % 2 == 0 ? "10px" : "0px",
                  marginRight: num % 2 == 0 ? "0px" : "10px",
                }}
              />
            ) : (
              <BusinessIcon
                sx={{
                  marginLeft: num % 2 == 0 ? "10px" : "0px",
                  marginRight: num % 2 == 0 ? "0px" : "10px",
                }}
              />
            )}
            <p style={styles.nomargin}>{subtitle}</p>
          </Stack>
          <Stack
            direction={num % 2 == 0 ? "row-reverse" : "row"}
            alignItems={"center"}
            justifyContent={num % 2 == 0 ? "flex-start" : "flex-start"}
          >
            <CalendarTodayIcon
              sx={{
                marginLeft: num % 2 == 0 ? "10px" : "0px",
                marginRight: num % 2 == 0 ? "0px" : "10px",
              }}
            />
            <p style={styles.midmargin}>{date}</p>
          </Stack>
          <Stack
            direction={num % 2 == 0 ? "row-reverse" : "row"}
            alignItems={"center"}
            justifyContent={num % 2 == 0 ? "flex-start" : "flex-start"}
          >
            <LocationOnIcon
              sx={{
                marginLeft: num % 2 == 0 ? "10px" : "0px",
                marginRight: num % 2 == 0 ? "0px" : "10px",
              }}
            />
            <p style={styles.nomargin}>{location}</p>
          </Stack>
        </div>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot sx={{ backgroundColor: color }}>
          <img
            src={img}
            style={{
              height: "50px",
              width: "50px",
              objectFit: "contain",
              margin: 2,
            }}
          />
        </TimelineDot>

        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Card
          sx={{
            backdropFilter: "blur(10px)",
            backgroundColor: "rgba(255,255,255,0.1)",
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <h2 style={styles.nomargin}>{title}</h2>
          <p style={styles.body}>{body}</p>
        </Card>
      </TimelineContent>
    </TimelineItem>
  );
}

const styles = {
  nomargin: {
    margin: 0,
  },
  midmargin: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  body: {
    margin: 0,
    textAlign: "justify",
  },
};
