import React from "react";
import { Grid , Typography , Box } from "@mui/material";
import YouTube from 'react-youtube';

export default function MaskRCNN(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    MaskRCNN
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Transfer Learning for Object Segmentation
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    In my last semester at Arts & Métiers, our teachers gave us a challenge : create a robotic arm from scrach which has to be able to automatically pick up and place
                    different objects and put them in the right rack. I worked on the computer vision part. I had to use the video feed to detect the different objects and classify them. 
                    I decided to setup a mask segmentation algorithm. The masks would give us the centroid of the object which would be sent to the arm for the pickup stage while the 
                    classifying part will help the arm to know where to place the object. 
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How we did it
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    We used transfer learning for this project. We used the weights of an existing algorithm trained on numerous object. We then manually annotated all our images.
                    We finally trained the last layers of our network with our data.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    Results
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    You can see the result below ! We had more than 90% accuracy and we reinforced the results of classification part by using the results of the series of
                    images which would 'vote' for the final result. The algorithm was really reliable and there was no problems whatsoever during the live demonstration. 
                </Typography>
                <Box style={{marginLeft:'auto',marginRight:'auto',maxWidth:'1000px',marginTop:'50px'}}>
                    <YouTube videoId="PqA8sr7Ylg0" opts={{width:'100%'}} />
                </Box>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}