import React from "react";
import { Grid , Typography , Box } from "@mui/material";

export default function SlabFlow(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    SlabFlow
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    Raw Material Routing Optimization for a Hot Rolling Mill
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    I arrived mid project after one of my colleague left . Base Structure was here and my mission
                    was to track bugs and deploy the model in production after the supply chain validated its performances.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    A bit of Terminology
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    A slab is a thick plate of metal. They vary in shape and weights a few (dozens) metric tons.
                    A conveyor belt is like a very big threadmill that can move a few slabs from a wharehouse to the next.
                    A Terberg is a vehicle similar to a truck that can transport a few slabs from the outdoor areas to the conveyor
                    which serves as access points to wharehouses.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    The Goal of the Project
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    Given the Planning of the furnace , the model aims to provide the what, when and how.
                    The model optimizes routing using a Gantt like structure to make sure slabs arrive at the
                    entry point of the furnace on time. 
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How it works
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    The model generates every possible combination and tries to limit a cost function that reflects
                    the incentives for the model : make the slabs available as soon as possible in the right order. Indeed,
                    since the slabs are ordered in stacks (may it be indoor or in the different warehouses) the order of it all is
                    central to the whole system.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    How is it going
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    I am still working on the project as I write these lines. It is almost ready for production. A few weeks of
                    bug hunting and a few tryouts in the warehouse and this will be a great success !
                </Typography>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}