import * as React from 'react';
import Timeline from '@mui/lab/Timeline';

import { experience } from '../../utils/consts';
import CustomizedTimelineItemWide from '../atom/CustomizedTimelineItemWide';
import CustomizedTimelineItemNarrow from '../atom/CustomizedTimelineItemNarrow';

export default function CustomizedTimeline() {
    const [screenSize, setScreenSize] = React.useState(getCurrentDimension());

    function getCurrentDimension(){
      return {
            width: window.innerWidth,
            height: window.innerHeight
      }
    }

    React.useEffect(() => {
          const updateDimension = () => {
                setScreenSize(getCurrentDimension())
          }
          window.addEventListener('resize', updateDimension);
  
      
          return(() => {
              window.removeEventListener('resize', updateDimension);
          })
    }, [screenSize])
  return (
    <Timeline 
      position={screenSize.width < 900 ? 'right' : "alternate"} 
      sx={screenSize.width < 900 ?  styles.small_screen : styles.wide_screen}>
      {experience.slice(0).reverse().map( (e , i) => {
        return (
          screenSize.width > 900 ?
            <CustomizedTimelineItemWide
                key={i}
                num={i}
                title={e.title}
                date={e.date}
                subtitle={e.subtitle}
                location={e.location}
                body={e.body}
                img={e.img}
                color={e.color}
            /> :
            <CustomizedTimelineItemNarrow
                key={i}
                num={i}
                title={e.title}
                date={e.date}
                subtitle={e.subtitle}
                location={e.location}
                body={e.body}
                img={e.img}
                color={e.color}
            />
        )
      })}
    </Timeline>
  );
}

const styles = {
  small_screen : {
    margin:'auto',
    marginLeft:'-98vw',
    width:'195vw'
  },
  wide_screen : {
    width:'70vw',
    margin:'auto'
  }
}