import React from "react";
import { Grid , Typography , Box } from "@mui/material";
import YouTube from 'react-youtube';

export default function GymCam(){
    const textStyle = {textAlign:'justify',maxWidth:'1000px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px'}
    return(
        <Grid container sx={{padding:'20px',fontSize:{xs:60,md:100}}}>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{textAlign:'center'}}>
                    GymCam
                </Typography>
                <Typography variant="h5" sx={{textAlign:'center'}}>
                    AI powered Fitness Assistant
                </Typography>
            </Grid>
            <Grid item xs={12} alignItems="center" style={{marginTop:'50px'}}>
                <Typography variant="h4" style={textStyle}>
                    Introduction
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    Tracking progression is essential in order to balance work out sessions and get that confidence boost every sportsman need
                    from time to time. To do so , we have several options , have a small notebook and a pen and note every details about our training
                    in there or use an App which share the same purpose. Tracking progress with a notebook can be tedious and not very fun.With an App,
                    its much better and easier with graphs , filtering possibilities and so on. The problem they both share is the input. i truly hated
                    writing down my sessions informations manually and most apps were not very well designed which did not helped.
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    I decided I was going to make my own app. With a design much more suited to my needs (and by extension a lot of other people too).
                    As i was designing It , I came up with a way to leverage computer vision to pre fill these session reports. The idea was to film
                    yourself while excersising and an AI could determine at any time which exercise you are doing and how much repetitions you did. That
                    way you would only need to check if the information is correct and add some comments and the weight you used if needed.
                </Typography>
                <Typography variant="h4" style={textStyle}>
                    Progression
                </Typography>
                <Typography variant="h6" style={textStyle}>
                    At this moment the front end part of the App is almost finished. I am currently working on the AI part and the architecture of the
                    backend. Since we won't be able to process the video directly from the phone. I am also currently looking into streaming processes and
                    asynchronous ones , comparing costs and so on. This is a very interesting project as it requires me to think ahead. Taking into
                    considerations various technological and financial limits to determine whether the project can be profitable in the long run. I could
                    discuss a long time about these aspects , feel free to DM me if you want to know more !
                </Typography>
            </Grid>
            <Box sx={{flex:"1 1 auto"}}/>
        </Grid>
    )
}